import '@/styles/styles.css';

import * as Sentry from '@sentry/node';

import React, { useState } from 'react';
import { determineIfPathnameIsWhitelisted, determineIfRegionBlocked } from '@/utils/regionBlocking';

import { AppProps } from 'next/app';
import Blocked from '@/pages/blocked';
import { ChakraProvider } from '@chakra-ui/react';
import { FULL_STORY_ORG_ID } from '@/constants';
import FullStory from 'react-fullstory';
import Layout from '@/components/layout/Layout';
import NextApp from 'next/app';
import { RewriteFrames } from '@sentry/integrations';
import { TransactionContextProvider } from '@/contexts/TransactionContext';
import { WalletModalContextProvider } from '@/contexts/WalletModalContext';
import { Web3ReactProvider } from '@web3-react/core';
import getConfig from 'next/config';
import getLibrary from '@/utils/getLibrary';
import theme from '@/styles/theme';
import useCheckIfQueryParamsHydrated from '@/hooks/useCheckIfQueryParamsHydrated';
import useOnLoadPageTracking from '@/hooks/useOnLoadPageTracking';
import { useRouter } from 'next/router';
import useRouterEventsForProgressBar from '@/hooks/useRouterEventsForProgressBar';

interface CozyAppProps extends AppProps {
  clientIpAddress: string;
  countryCode: string;
  isRegionBlocked: boolean;
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const App = ({ countryCode, clientIpAddress, isRegionBlocked, Component, pageProps }: CozyAppProps): JSX.Element => {
  const [blocked, setBlocked] = useState(false);
  if (isRegionBlocked && !blocked) {
    setBlocked(true);
  }

  const queryParamsHydrated = useCheckIfQueryParamsHydrated();
  const { pathname } = useRouter();

  useRouterEventsForProgressBar();
  useOnLoadPageTracking();

  const pathnameIsWhitelisted = determineIfPathnameIsWhitelisted(pathname);

  if ((blocked || isRegionBlocked) && !pathnameIsWhitelisted) {
    return (
      <ChakraProvider theme={theme}>
        <Blocked countryCode={countryCode} clientIpAddress={clientIpAddress} />
      </ChakraProvider>
    );
  }

  const mainJsx = (
    <ChakraProvider theme={theme}>
      <TransactionContextProvider>
        <WalletModalContextProvider>
          <FullStory org={FULL_STORY_ORG_ID} />
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </WalletModalContextProvider>
      </TransactionContextProvider>
    </ChakraProvider>
  );

  // Don't load Web3ReactProvider until router query params have hydrated
  if (!queryParamsHydrated) {
    return mainJsx;
  }

  return <Web3ReactProvider getLibrary={getLibrary}>{mainJsx}</Web3ReactProvider>;
};

App.getInitialProps = async (appContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  const {
    ctx: { req },
  } = appContext;

  const countryCode = req?.headers['x-vercel-ip-country'];
  const host = req?.headers['host'];

  const isRegionBlocked = await determineIfRegionBlocked(countryCode, host);

  return { isRegionBlocked, ...appProps };
};

export default App;
