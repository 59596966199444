import { Alert, AlertDescription } from '@chakra-ui/react';

import React from 'react';
import Text from '@/components/shared/Text';

const WalletConnectError: React.FC = ({}) => {
  return (
    <Alert status="error" mb={8} p={4}>
      <AlertDescription fontSize="md" fontWeight="600">
        There was an issue connecting your wallet.
        <Text fontWeight="400" fontSize="sm" mt={1}>
          If you just installed your wallet, please refresh and try again.
        </Text>
      </AlertDescription>
    </Alert>
  );
};

export default WalletConnectError;
