import { Icon, IconButton } from '@chakra-ui/react';

import { BiMenu } from 'react-icons/bi';
import React from 'react';

interface Props {
  btnRef: React.MutableRefObject<undefined>;
  onClick: () => void;
}

const DrawerButton: React.FC<Props> = ({ btnRef, onClick }: Props) => {
  return (
    <IconButton
      display={{ base: 'block', md: 'none' }}
      aria-label="Open Menu"
      backgroundColor="transparent"
      icon={<Icon as={BiMenu} boxSize={8} color="gray.400" />}
      onClick={onClick}
      ref={btnRef}
      size="lg"
    />
  );
};

export default DrawerButton;
