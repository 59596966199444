import { HeadingProps } from '@chakra-ui/react';
import React from 'react';
import Text from '../shared/Text';
import { abbreviateEthAddress } from '@/helpers/walletHelpers';
import { useWeb3React } from '@web3-react/core';

const Account: React.FC = (props: HeadingProps) => {
  const { account } = useWeb3React();

  return (
    <Text fontWeight="500" {...props}>
      {account === null ? '-' : abbreviateEthAddress(account)}
    </Text>
  );
};

export default Account;
