import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Flex } from '@chakra-ui/react';

import CozyLogo from '../CozyLogo';
import DrawerLink from './DrawerLink';
import React from 'react';

interface Props {
  btnRef: React.MutableRefObject<undefined>;
  isOpen: boolean;
  onClose: () => void;
}

const MobileNavDrawer: React.FC<Props> = ({ isOpen, onClose, btnRef }: Props) => {
  return (
    <>
      <Drawer placement="left" isOpen={isOpen} onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton color="gray.500" size="lg" />

            <DrawerBody>
              <CozyLogo full height={40} />
              <Flex flexDir="column" alignItems="flex-start" mt={6}>
                <DrawerLink value="Dashboard" href="/dashboard" onClick={onClose} />
                <DrawerLink value="Your Funds" href="/funds" onClick={onClose} />
                <DrawerLink value="Borrow Assets" href="/borrow" onClick={onClose} />
                <DrawerLink value="Protected Investing" href="/protected-invest" onClick={onClose} />
                <DrawerLink value="Protected Borrowing" href="/protected-borrow" onClick={onClose} />
                <DrawerLink value="Provide Protection" href="/provide" onClick={onClose} />
                <DrawerLink value="Developers" href="/developers" onClick={onClose} />
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
export default MobileNavDrawer;
