import Link from '@/components/shared/Link';
import React from 'react';
import { Stack } from '@chakra-ui/react';
import Text from '@/components/shared/Text';

interface ListItem {
  isExternal: boolean;
  name: string;
  url: string;
}

interface Props {
  title: string;
  listItems: Array<ListItem>;
}

const FooterList: React.FC<Props> = ({ title, listItems }: Props) => {
  return (
    <Stack spacing={4} shouldWrapChildren justifySelf="center">
      <Text color="gray.300" textTransform="uppercase" fontWeight="700" fontSize="xs">
        {title}
      </Text>
      <nav>
        {listItems.map(({ isExternal, name, url }, index) => (
          <Link
            display="block"
            isExternal={isExternal}
            href={url}
            key={index}
            color="gray.500"
            fontWeight="600"
            fontSize="md"
            lineHeight="none"
            mb={4}
          >
            {name}
          </Link>
        ))}
      </nav>
    </Stack>
  );
};
export default FooterList;
