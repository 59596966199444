import React, { ReactNode } from 'react';

import MenuItem from '@/components/shared/MenuItem';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const WalletMenuItem: React.FC<Props> = ({ children, onClick }: Props) => {
  return (
    <MenuItem px={4} fontWeight="600" fontSize="md" py={3} onClick={onClick}>
      {children}
    </MenuItem>
  );
};
export default WalletMenuItem;
