import {
  Account,
  EthereumTransaction,
  EthereumTransactionTypes,
  Market,
  PositionTypes,
  ProtectionRowLayout,
} from '@/types';
import { Box, Flex, useTheme } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { calculatePositionStats, checkIfPositionHasBalance } from '@/helpers/positionHelpers';

import AlternateTriggerInfoCombinedCell from './AlternateTriggerInfoCombinedCell';
import AssetCell from './AssetCell';
import Button from '../shared/Button';
import Divider from '../shared/Divider';
import Skeleton from '../shared/Skeleton';
import { SupplyModalTabs } from '../modals/SupplyModal';
import TableRow from '../shared/Table/TableRow';
import Text from '@/components/shared/Text';
import TriggerRowCell from '../provide/TriggerRowCell';
import TriggeredHighlightCell from './ProtectionTriggeredAlert';
import { calculateMarketStats } from '@/helpers/marketHelpers';
import { checkIfTransactionsHavePendingTransaction } from '@/helpers/transactionHelpers';
import { findPositionByMarketId } from '@/helpers/findHelpers';
import { useWeb3React } from '@web3-react/core';

interface Props {
  account?: Account;
  allInactiveMarkets?: Market[];
  columnAlignments: ('left' | 'right')[];
  columnWidths: number[];
  index: number;
  isDefault?: boolean;
  layout?: ProtectionRowLayout;
  loading?: boolean;
  market: Market;
  onOpen: (market, tab) => () => void;
  relatedMarkets?: Market[];
  setExpandedDefaultMarketIds?: React.Dispatch<React.SetStateAction<any[]>>;
  transactions: EthereumTransaction[];
}

const SupplyRow: React.FC<Props> = ({
  account,
  columnAlignments,
  index,
  isDefault,
  layout = ProtectionRowLayout.Default,
  loading,
  market,
  columnWidths,
  onOpen,
  relatedMarkets,
  setExpandedDefaultMarketIds,
  transactions,
}: Props) => {
  const theme = useTheme();
  const { trigger } = market;

  const isTriggered = trigger?.triggered;

  const hasPendingTransaction = checkIfTransactionsHavePendingTransaction(
    transactions,
    [EthereumTransactionTypes.Deposit, EthereumTransactionTypes.Redeem],
    market.id,
  );

  const { formattedMarketSizeUsd } = calculateMarketStats(market);
  const { account: walletAccount } = useWeb3React();

  const position = findPositionByMarketId(account, market.id);

  const {
    formattedLifetimeEarnings,
    formattedUSDLifetimeEarnings,
    formattedSupplyRate,
    formattedSupplyTokenBalance,
    formattedUSDSupplyBalance,
  } = calculatePositionStats(position, market);

  const columnIncrement = market.triggerAddress != null ? 1 : 0;
  const positionHasBalance = checkIfPositionHasBalance(position, PositionTypes.Supply, market);

  const primaryAction = positionHasBalance
    ? onOpen(market, SupplyModalTabs.SupplyBalance)
    : onOpen(market, SupplyModalTabs.Deposit);

  return (
    <Box>
      {index != 0 && <Divider />}
      <TableRow isPending={hasPendingTransaction} px={6} py={4} alignItems="center" onClick={primaryAction}>
        {layout === ProtectionRowLayout.Default && (
          <AssetCell
            flex={columnWidths[0]}
            market={market}
            position={position}
            showBalance={walletAccount != null}
            walletAccount={walletAccount}
          />
        )}

        {layout === ProtectionRowLayout.Default && market.triggerAddress && (
          <TriggerRowCell
            flex={columnWidths[1]}
            market={market}
            positionType={PositionTypes.Supply}
            relatedMarkets={relatedMarkets}
            setExpandedDefaultMarketIds={setExpandedDefaultMarketIds}
          />
        )}

        {isTriggered && (
          <Flex justifyContent="center" alignItems="center" flex={columnWidths[2] + columnWidths[3] + columnWidths[4]}>
            <TriggeredHighlightCell maxW="16em" positionType={PositionTypes.Supply} size="sm" trigger={trigger} />
          </Flex>
        )}

        {layout === ProtectionRowLayout.Alternate && (
          <AlternateTriggerInfoCombinedCell
            flex={columnWidths[0] + columnWidths[1]}
            isDefault={isDefault}
            market={market}
          />
        )}

        {!isTriggered && (
          <Box
            flex={columnWidths[1 + columnIncrement]}
            textAlign={columnAlignments[1 + columnIncrement]}
            alignItems="center"
          >
            <Text fontSize="md" fontWeight="600" color={theme.utilityColors.successColor}>
              {formattedSupplyRate}
            </Text>
          </Box>
        )}

        {!isTriggered && (
          <Box flex={columnWidths[2 + columnIncrement]} textAlign={columnAlignments[2 + columnIncrement]}>
            <Text fontSize="md" color="gray.500">
              {formattedMarketSizeUsd}
            </Text>
          </Box>
        )}

        {!isTriggered && (
          <Box
            flex={columnWidths[3 + columnIncrement]}
            textAlign={columnAlignments[3 + columnIncrement]}
            alignItems="center"
          >
            <Skeleton isLoaded={!loading}>
              {positionHasBalance && (
                <>
                  <Text fontSize="md" fontWeight="600" mb={1}>
                    {formattedUSDLifetimeEarnings}
                  </Text>
                  <Text fontWeight="400" fontSize="sm" color="gray.500">
                    {`${formattedLifetimeEarnings} ${market.underlyingSymbol}`}
                  </Text>
                </>
              )}
            </Skeleton>
          </Box>
        )}

        <Box flex={columnWidths[4 + columnIncrement]} textAlign={columnAlignments[4 + columnIncrement]}>
          <Skeleton isLoaded={!loading}>
            <Text fontSize="md" fontWeight="600" mb={1}>
              {formattedUSDSupplyBalance}
            </Text>
            {positionHasBalance && (
              <Text fontWeight="400" fontSize="sm" color="gray.500">
                {`${formattedSupplyTokenBalance} ${market.underlyingSymbol}`}
              </Text>
            )}
          </Skeleton>
        </Box>

        <Flex flex={columnWidths[5 + columnIncrement]} justifyContent="flex-end">
          {chooseButton(market, hasPendingTransaction, positionHasBalance, onOpen)}
        </Flex>
      </TableRow>
    </Box>
  );
};

const chooseButton = (
  market: Market,
  hasPendingTransaction: boolean,
  positionHasBalance: boolean,
  onOpen: (market: Market, tab: SupplyModalTabs) => () => void,
): ReactNode => {
  if (hasPendingTransaction) {
    return <Button isDisabled>Pending</Button>;
  } else if (positionHasBalance) {
    return <Button onClick={onOpen(market, SupplyModalTabs.SupplyBalance)}>Manage</Button>;
  } else {
    return <Button onClick={onOpen(market, SupplyModalTabs.Deposit)}>Deposit</Button>;
  }
};

export default SupplyRow;
