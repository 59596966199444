import { Box, Flex, Stack, useTheme } from '@chakra-ui/react';
import { MarketTypes, PositionTypes } from '@/types';
import React, { useContext, useEffect } from 'react';
import { SortFields, sortMarkets } from '@/helpers/sortHelpers';
import useFetchMarketData, { SubgraphData } from '@/hooks/useFetchMarketData';

import BorrowLimitPanel from '@/components/shared/BorrowingPowerPanel';
import CommonModals from '@/components/modals/CommonModals';
import Head from 'next/head';
import { MIN_POSITION_BALANCE } from '@/helpers/positionHelpers';
import NoMarketsFound from '@/components/funds/NoMarketsFound';
import PageHeader from '@/components/shared/PageHeader';
import Stat from '@/components/shared/Stat';
import StatContainer from '@/components/shared/StatContainer';
import SupplyRow from '@/components/funds/SupplyRow';
import Table from '@/components/shared/Table/Table';
import TableEmptySpace from '@/components/shared/Table/TableEmptySpace';
import TheGraphLoadingTag from '@/components/shared/TheGraphLoadingTag';
import { TransactionContext } from '@/contexts/TransactionContext';
import { calculateAccountStats } from '@/helpers/accountHelpers';
import { conditionallyToastVaultJustSynced } from '@/helpers/transactionHelpers';
import { formatNumber } from '@/helpers/formatHelpers';
import { onManageModalOpen } from '@/helpers/manageModalHelpers';
import useFetchAccountData from '@/hooks/useFetchAccountData';
import { useRouter } from 'next/router';
import { useSortRouterParams } from '@/hooks/useSortRouterParams';
import { useWeb3React } from '@web3-react/core';

interface Props {
  initialSubgraphData: SubgraphData;
}

export const fundsSubnav = [
  { href: '/funds', label: 'Deposit Funds' },
  { href: '/borrow', label: 'Borrow Funds' },
];

const Funds: React.FC<Props> = ({ initialSubgraphData }: Props) => {
  const theme = useTheme();
  const router = useRouter();
  const { account: walletAccount } = useWeb3React();
  const { sortBy, sortOrder } = useSortRouterParams();

  const { comptroller, markets } = useFetchMarketData(initialSubgraphData);
  const { account, loadingAccountData, errorLoadingAccountData } = useFetchAccountData();

  const { transactions, setTransactions, toast } = useContext(TransactionContext);

  useEffect(() => {
    conditionallyToastVaultJustSynced(transactions, account?.positions, setTransactions, toast);
  });

  const { totalSupplyBalance, totalSupplyInterestAccrued, currentSupplyInterestRate } = calculateAccountStats(
    account,
    MarketTypes.NonProtectionMarket,
  );

  const unprotectedMarkets = markets.filter((market) => market.triggerAddress == null);

  const { activeMarkets, inactiveMarkets, hasMarkets, hasActiveMarkets } = sortMarkets(
    unprotectedMarkets,
    account,
    ['supplyBalanceUnderlying'],
    { field: sortBy, order: sortOrder },
  );

  const columnWidths = [2.5, 2, 2, 2, 2, 3];
  const columnAlignments: ('left' | 'right')[] = ['left', 'right', 'right', 'right', 'right', 'right'];

  const columnLabels = [
    { label: 'Asset', sortField: SortFields.Name },
    { label: 'Interest (APY)*', sortField: SortFields.SupplyRate },
    {
      label: 'Market Size',
      sortField: SortFields.MarketSize,
      tooltip: { title: 'Market Size', text: 'The total value of assets deposited into this market.' },
    },
    hasActiveMarkets
      ? { label: 'Total Earned', sortField: SortFields.SupplyInterestAccrued }
      : { label: '', sortField: null },
    { label: 'Your Balance', sortField: SortFields.SupplyBalance },
    { label: '', sortField: null },
  ];

  return (
    <>
      <Head>
        <title>Your Funds</title>
      </Head>

      <CommonModals account={account} comptroller={comptroller} markets={markets} positionType={PositionTypes.Supply} />

      <TheGraphLoadingTag errorLoading={errorLoadingAccountData} isLoading={loadingAccountData} />

      <Flex flexDir="column" alignItems="center" mb={16}>
        <PageHeader
          description="Deposit assets to earn interest and increase your Borrowing Power."
          title="Your Funds"
          subnav={fundsSubnav}
        />

        <Box maxW={theme.maxPageWidth} width="100%" px={{ base: 4, md: 6, xl: 8 }}>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={6}>
            <StatContainer flex={3}>
              <Flex>
                <Stat
                  loading={loadingAccountData}
                  flex={1}
                  label="Total Balance"
                  number={totalSupplyBalance < MIN_POSITION_BALANCE ? 0 : formatNumber(totalSupplyBalance, '0,0.00')}
                  unit="$"
                />
                <Stat
                  loading={loadingAccountData}
                  color={theme.utilityColors.successColor}
                  flex={1}
                  label="You've Earned"
                  number={
                    totalSupplyInterestAccrued < MIN_POSITION_BALANCE ? 0 : formatNumber(totalSupplyInterestAccrued)
                  }
                  unit="$"
                />
              </Flex>
            </StatContainer>

            <StatContainer flex={1}>
              <Stat
                loading={loadingAccountData}
                color={theme.utilityColors.successColor}
                label="Currently Earning (APY)*"
                number={formatNumber(currentSupplyInterestRate * 100, '0.0')}
                unit="%"
              />
            </StatContainer>
          </Stack>

          <Table columnAlignments={columnAlignments} columnLabels={columnLabels} columnWidths={columnWidths}>
            {!hasMarkets && <NoMarketsFound />}

            {activeMarkets.map((market, index) => (
              <SupplyRow
                account={account}
                loading={loadingAccountData}
                columnAlignments={columnAlignments}
                columnWidths={columnWidths}
                index={index}
                key={market.id}
                market={market}
                onOpen={onManageModalOpen(router)}
                transactions={transactions}
              />
            ))}

            {activeMarkets.length > 0 && inactiveMarkets.length > 0 && <TableEmptySpace />}

            {inactiveMarkets.map((market, index) => (
              <SupplyRow
                account={account}
                loading={loadingAccountData}
                columnAlignments={columnAlignments}
                columnWidths={columnWidths}
                index={index}
                key={market.id}
                market={market}
                onOpen={onManageModalOpen(router)}
                transactions={transactions}
              />
            ))}
          </Table>
        </Box>

        {walletAccount && <BorrowLimitPanel account={account} />}
      </Flex>
    </>
  );
};

// export const getStaticProps: GetStaticProps = async () => {
//   return preloadSubgraphData();
// };

export default Funds;
