import { MenuItem as ChakraMenuItem, MenuItemProps } from '@chakra-ui/react';

import React from 'react';

type Props = MenuItemProps;

const MenuItem: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <ChakraMenuItem _hover={{ bg: 'gray.50' }} _focus={{ bg: 'gray.50' }} {...props}>
      {children}
    </ChakraMenuItem>
  );
};
export default MenuItem;
