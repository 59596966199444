import { Button } from '@chakra-ui/react';
import Link from 'next/link';
import React from 'react';
import Text from '@/components/shared/Text';

interface Props {
  href: string;
  onClick: () => void;
  value: string;
}

const ButtonStyles = {
  _focused: {
    backgroundColor: 'transparent',
  },
};

const DrawerLink: React.FC<Props> = ({ href, onClick, value }: Props) => {
  return (
    <Link href={href} passHref>
      <Button onClick={onClick} {...ButtonStyles} variant="ghost">
        <Text size="md">{value}</Text>
      </Button>
    </Link>
  );
};
export default DrawerLink;
