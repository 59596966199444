import { Box, Flex } from '@chakra-ui/react';

import Footer from '@/components/layout/footer/Footer';
import Header from '@/components/layout/Header';
import React from 'react';
import dynamic from 'next/dynamic';

const MigrationBanner = dynamic(() => import('./MigrationBanner'));
const WalletLoader = dynamic(() => import('./WalletLoader'));

interface Props {
  children: JSX.Element;
}

const Layout: React.FunctionComponent = ({ children }: Props) => {
  return (
    <Flex minH="100vh" flexDirection="column" align="center" flexShrink={0}>
      <MigrationBanner />
      <WalletLoader />
      <Header />
      <Box flex={1} width="100%">
        <main style={{ width: '100%' }}>{children}</main>
      </Box>
      <Footer />
    </Flex>
  );
};

export default Layout;
