import {
  Box,
  Center,
  Button as ChakraButton,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  useTheme,
} from '@chakra-ui/react';
import React, { useContext } from 'react';

import Account from './Account';
import { BiCaretDown } from 'react-icons/bi';
import Button from '@/components/shared/Button';
import ConnectWalletModal from '@/components/modals/ConnectWalletModal';
import Divider from '../shared/Divider';
import Dot from '@/components/shared/Dot';
import { ETH_NETWORKS_DISPLAY_NAMES } from '@/constants';
import Text from '@/components/shared/Text';
import WalletMenuItem from './WalletMenuItem';
import { WalletModalContext } from '@/contexts/WalletModalContext';
import { Web3Provider } from '@ethersproject/providers';
import { trackEvent } from '@/utils/analytics';
import { useWeb3React } from '@web3-react/core';

const Wallet: React.FC = ({}) => {
  const { active, chainId, deactivate } = useWeb3React<Web3Provider>();
  const theme = useTheme();
  const { onClose, isOpen, onOpen } = useContext(WalletModalContext);

  const disconnectWallet = () => {
    trackEvent('Wallet Disconnected');
    deactivate();
  };

  const switchWallet = () => {
    trackEvent('Switch Wallet Clicked');
    onOpen();
  };

  if (!active) {
    return (
      <>
        <ConnectWalletModal isOpen={isOpen} onClose={onClose} />
        <Flex align="center">
          <Button onClick={onOpen}>
            <Box mr={2} mt="1px">
              <Dot color={theme.utilityColors.accentColor} />
            </Box>
            Connect Wallet
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <>
      <ConnectWalletModal isOpen={isOpen} onClose={onClose} />

      <Menu closeOnSelect={false}>
        <MenuButton as={ChakraButton} variant="ghost">
          <Stack isInline align="center" spacing={2} shouldWrapChildren>
            <Box mb="1px" mr={2}>
              <Dot color={theme.utilityColors.successColor} />
            </Box>
            <Account />
            <Icon as={BiCaretDown} mb="3px" ml={1} />
          </Stack>
        </MenuButton>

        <MenuList pt={4} pb={0} border="0px" borderRadius="8px" boxShadow="lg">
          <Box px={2}>
            <WalletMenuItem onClick={disconnectWallet}>Disconnect wallet</WalletMenuItem>
            <WalletMenuItem onClick={switchWallet}>Switch wallet</WalletMenuItem>
          </Box>
          <Divider mt={4} />
          <Center pt={2} pb={3} mt={2} borderRadius="8px" borderTopLeftRadius={0} borderTopRightRadius={0}>
            <Text fontStyle="italic" fontSize="md" color="gray.500">
              {ETH_NETWORKS_DISPLAY_NAMES[chainId]}
            </Text>
          </Center>
        </MenuList>
      </Menu>
    </>
  );
};

export default Wallet;
