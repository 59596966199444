import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { fortmatic, injected, portis, walletconnect, walletlink } from '@/connectors';

import Button from '@/components/shared/Button';
import Dot from '../shared/Dot';
import Heading from '../shared/Heading';
import { Image } from '@/components/shared/Image';
import Link from '@/components/shared/Link';
import Text from '@/components/shared/Text';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import WalletConnectError from '../wallet/WalletConnectError';
import { Web3Provider } from '@ethersproject/providers';
import { trackEvent } from '@/utils/analytics';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

enum ConnectorNames {
  Injected = 'Injected',
  WalletConnect = 'WalletConnect',
  WalletLink = 'Coinbase Wallet',
  Fortmatic = 'Fortmatic',
  Portis = 'Portis',
}

enum ConnectorLogos {
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
  'Coinbase Wallet' = 'coinbase-wallet',
  Fortmatic = 'Fortmatic',
  Portis = 'Portis',
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.WalletLink]: walletlink,
  [ConnectorNames.Fortmatic]: fortmatic,
  [ConnectorNames.Portis]: portis,
};

const ConnectWalletModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const theme = useTheme();
  const context = useWeb3React<Web3Provider>();

  const { connector, activate } = context;

  const [connectError, setconnectError] = useState<boolean>(false);
  const [isMetaMask, setIsMetaMask] = useState<boolean>(false);
  const [activatingConnector, setActivatingConnector] = React.useState<any>();

  useEffect(() => setIsMetaMask(window?.ethereum?.isMetaMask), []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setconnectError(false);
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent borderRadius="lg" mx={2}>
        <ModalHeader p={0} m={10} mb={0}>
          <Heading fontSize="xl">Connect your wallet</Heading>
        </ModalHeader>
        <ModalBody px={10} pb={10}>
          <Text fontSize="md" mb={8}>
            In order to use Cozy, choose a wallet to connect to. If you’re new to Ethereum, you can{` `}
            <Link href="https://ethereum.org/en/wallets/" isExternal>
              {` `} learn more here.
            </Link>
          </Text>

          {connectError && <WalletConnectError />}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            {Object.keys(connectorsByName).map((name) => {
              const currentConnector = connectorsByName[name];
              const activating = currentConnector === activatingConnector;
              const connected = currentConnector === connector;

              if (name === 'Injected' && (isMetaMask || !connected)) {
                name = 'MetaMask';
              }

              const onClick = () => {
                setActivatingConnector(currentConnector);
                activate(currentConnector, undefined, true)
                  .then(() => {
                    onClose();
                    setconnectError(false);
                    setActivatingConnector(undefined);

                    trackEvent('Wallet Connected', { provider: name });
                  })
                  .catch((error) => {
                    if (error instanceof UnsupportedChainIdError) {
                      activate(connector);
                    } else {
                      setconnectError(true);
                      setActivatingConnector(undefined);
                      if (
                        currentConnector instanceof WalletConnectConnector &&
                        currentConnector.walletConnectProvider?.wc?.uri
                      ) {
                        currentConnector.walletConnectProvider = undefined;
                      }
                    }
                  });
              };

              const loading = !connected && activating;

              return (
                <Button
                  isFullWidth
                  justifyContent="space-between"
                  key={name}
                  onClick={onClick}
                  loadingText={name}
                  disabled={loading}
                >
                  <Flex align="center" justifyContent="flex-start">
                    <Image src={`/images/wallets/${ConnectorLogos[name]}.png`} htmlWidth={20} htmlHeight={20} />
                    <Text ml={2}>{name}</Text>
                  </Flex>
                  {connected && <Dot color={theme.utilityColors.successColor} />}
                  {loading && <Spinner color="gray.500" size="xs" />}
                </Button>
              );
            })}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectWalletModal;
