import {
  Box,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react';
import { activityItemIsEligibleForNotif, markNotificationsSeen } from '@/helpers/activityItemHelpers';

import Button from '@/components/shared/Button';
import Divider from '@/components/shared/Divider';
import Dot from '@/components/shared/Dot';
import { FaRegBell } from 'react-icons/fa';
import React from 'react';
import createPersistedState from 'use-persisted-state';
import dynamic from 'next/dynamic';
import useFetchAccountData from '@/hooks/useFetchAccountData';
import useFetchInvestData from '@/hooks/useFetchInvestAccountData';
import useFetchMarketData from '@/hooks/useFetchMarketData';
import { useRouter } from 'next/router';

const RecentActivity = dynamic(() => import('@/components/activity/RecentActivity'), { ssr: false });

const useSeenNotificationIdsState = createPersistedState('seenNotificationsIds');

const ITEMS_TO_DISPLAY = 4;

const ActivityBell: React.FC = () => {
  const { markets } = useFetchMarketData();
  const { account: cozyAccount } = useFetchAccountData();
  const { account } = useFetchInvestData(cozyAccount, markets);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const theme = useTheme();
  const router = useRouter();

  const [seenNotificationIds, setSeenNotificationIds] = useSeenNotificationIdsState([]);

  const onNotificationButtonClick = () => {
    router.push('/activity');
    markNotificationsSeen(account, ITEMS_TO_DISPLAY, seenNotificationIds, setSeenNotificationIds);
    onClose();
  };

  const onPopoverClose = () => {
    markNotificationsSeen(account, ITEMS_TO_DISPLAY, seenNotificationIds, setSeenNotificationIds);
    onClose();
  };

  const notificationEligibleActivityItems = account?.activityItems?.filter((item) =>
    activityItemIsEligibleForNotif(item),
  );

  const unseenNotificaitonsExist =
    notificationEligibleActivityItems?.filter((item) => !seenNotificationIds.includes(item.event.id)).length > 0;

  return (
    <Popover isOpen={isOpen} onClose={onPopoverClose}>
      {unseenNotificaitonsExist && (
        <Box position="relative">
          <Box
            position="absolute"
            top="-12px"
            right="-37px"
            zIndex="docked"
            borderRadius="10px"
            borderColor="white"
            borderWidth="2px"
          >
            <Dot width={7} color={theme.utilityColors.errorColor} />
          </Box>
        </Box>
      )}
      <PopoverTrigger>
        <IconButton
          ml={1}
          onClick={onOpen}
          variant="ghost"
          colorScheme="black"
          aria-label="Activity"
          fontSize="16px"
          icon={<FaRegBell />}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <Box mt={2} mb={0}>
          <RecentActivity
            account={account}
            limitNumItems={ITEMS_TO_DISPLAY}
            seenNotificationIds={seenNotificationIds}
          />
          <Divider mt={2} />
          <Button
            borderTopRadius="0px"
            borderBottomRadius="md"
            height="48px"
            size="sm"
            width="100%"
            variant="ghost"
            onClick={onNotificationButtonClick}
          >
            See all notifications
          </Button>
        </Box>
      </PopoverContent>
    </Popover>
  );
};
export default ActivityBell;
