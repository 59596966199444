import { Box, Flex, SimpleGrid, useTheme } from '@chakra-ui/react';
import { COZY_DEVELOPER_DOCS_URL, COZY_JOBS_URL, COZY_TWITTER_URL } from '@/constants';

import CozyLogo from '../CozyLogo';
import FooterList from './FooterList';
import React from 'react';
import Text from '@/components/shared/Text';

const footerLists = [
  {
    title: 'Cozy',
    listItems: [
      { isExternal: false, name: 'Home', url: '/' },
      { isExternal: true, name: 'Jobs', url: COZY_JOBS_URL },
      { isExternal: false, name: 'Privacy', url: '/privacy' },
      { isExternal: false, name: 'Terms', url: '/terms' },
    ],
  },
  {
    title: 'Developers',
    listItems: [
      { isExternal: false, name: 'Overview', url: '/developers' },
      { isExternal: true, name: 'Docs', url: COZY_DEVELOPER_DOCS_URL },
    ],
  },
  {
    title: 'Get in Touch',
    listItems: [
      // TODO: ADD ME BACK IN AFTER OPENING UP DISCORD SERVER { isExternal: true, name: 'Discord', url: COZY_DISCORD_URL },
      { isExternal: true, name: 'Twitter', url: COZY_TWITTER_URL },
    ],
  },
];

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <footer style={{ width: '100%' }}>
      <Flex justify="center">
        <Box
          mt={{ base: 8, lg: 16 }}
          mb={{ base: 8, lg: 12 }}
          px={{ base: 4, md: '4rem' }}
          maxW={theme.maxPageWidth}
          width="100%"
        >
          <Flex direction={{ base: 'column', md: 'row' }} justify="space-between">
            <SimpleGrid columns={4} flex={1} spacingX={4} justifyContent="center">
              <Box ml={4} justifySelf="center">
                <CozyLogo full height={46} link={false} color="gray" />
              </Box>
              {footerLists.map((footerList) => (
                <FooterList key={footerList.title} {...footerList} />
              ))}
            </SimpleGrid>
          </Flex>
        </Box>
      </Flex>

      <Text color="gray.300" fontSize="sm" textAlign="center" mb={12} mx={4}>
        *Deposits and earned interest are subject to market volatility. The total real value of the assets may be a loss
        even if earning yield.
      </Text>

      <Flex align="center" justify="center" minH="64px" borderTop="1px" borderTopColor="gray.50">
        <Text color="gray.300" fontSize="sm" fontWeight="600" textAlign="center" mt={6} mb={6}>
          © 2021 Cozy Finance. All rights reserved.
        </Text>
      </Flex>
      <Box height="8px" background="linear-gradient(90deg, #D27FF8 0%, #5057FA 100%)" />
    </footer>
  );
};
export default Footer;
