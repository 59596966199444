import AnimatedSlothFace, { AnimatedSlothFaceVariants } from '@/components/shared/AnimatedSlothFace';
import { Flex, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';

import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import Text from '@/components/shared/Text';
import { trackEvent } from '@/utils/analytics';
import { useRouter } from 'next/router';

interface Props {
  countryCode: string;
  clientIpAddress: string;
}

const Blocked: React.FC<Props> = ({ clientIpAddress, countryCode }: Props) => {
  const router = useRouter();

  useEffect(() => trackEvent('Viewed Block Page', { clientIpAddress, countryCode }));

  const onClick = () => {
    trackEvent('Cozy Home Clicked', { location });
    router.push('/');
  };

  return (
    <Flex alignItems="center" justifyContent="center" mt="10em" mb="5em">
      <Stack justifyContent="center" alignItems="center" direction="column" spacing={10}>
        <Stack justifyContent="center" alignItems="center" direction="column" spacing={2}>
          <AnimatedSlothFace variant={AnimatedSlothFaceVariants.Frowning} size={85} />
          <Text fontWeight="600" textAlign="center" color="gray.500" fontSize="xl">
            Cozy’s not yet available in your region
          </Text>
          <Text textAlign="center" color="gray.500" fontSize="lg">
            Check back soon though for updates!
          </Text>
        </Stack>
        <Button appButtonType={ButtonType.Primary} onClick={onClick}>
          Cozy Home
        </Button>
      </Stack>
    </Flex>
  );
};

export default Blocked;
