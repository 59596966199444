import { Box, Flex, HStack, useDisclosure, useTheme } from '@chakra-ui/react';

import ActivityBell from './header/ActivityBell';
import CozyLogo from '@/components/layout/CozyLogo';
import DrawerButton from './drawer/DrawerButton';
import HeaderLink from './header/HeaderLink';
import Headroom from 'react-headroom';
import MobileNavDrawer from './drawer/MobileNavDrawer';
import React from 'react';
import Wallet from '@/components/wallet/Wallet';

const Header: React.FC = () => {
  const theme = useTheme();
  const HEADER_HEIGHT = 80;
  const LOGO_HEIGHT = 45;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const mobileNavButtonRef = React.useRef();

  return (
    <Headroom wrapperStyle={{ width: '100%', height: HEADER_HEIGHT }}>
      <header style={{ width: '100%', height: HEADER_HEIGHT, backgroundColor: 'white' }}>
        <Flex
          direction="column"
          justify="center"
          height={`${HEADER_HEIGHT}px`}
          maxWidth={theme.maxPageWidth}
          margin="auto"
          pl={{ base: 1, md: 6, xl: 8 }}
          pr={{ base: 4, xl: 8 }}
        >
          <nav>
            <Flex maxW={theme.maxPageWidth} flex={1} direction="row" justify="space-between" align="center">
              <Flex flex={1} alignItems={{ base: 'flex-start', md: 'center' }} flexDir={{ base: 'column', md: 'row' }}>
                <Flex flexDir="row" align="center">
                  <DrawerButton onClick={() => onOpen()} btnRef={mobileNavButtonRef} />

                  <Box display={{ base: 'none', sm: 'block' }} ml="-5px" height={LOGO_HEIGHT}>
                    <CozyLogo height={LOGO_HEIGHT} full />
                  </Box>
                  <Box display={{ base: 'block', sm: 'none' }}>
                    <CozyLogo height={LOGO_HEIGHT} />
                  </Box>
                </Flex>
              </Flex>

              <HStack display={{ base: 'none', md: 'flex' }} my={1} spacing="2px">
                <HeaderLink text="Dashboard" href="/dashboard" />
                <HeaderLink text="Funds" href="/funds" />
                <HeaderLink text="Protected Investing" href="/protected-invest" />
                <HeaderLink text="Developers" href="/developers" />
              </HStack>

              <Flex alignItems="center" justify="flex-end" flex={1}>
                <Wallet />
                <ActivityBell />
              </Flex>
            </Flex>
          </nav>
        </Flex>

        <MobileNavDrawer isOpen={isOpen} onClose={onClose} btnRef={mobileNavButtonRef} />
      </header>
    </Headroom>
  );
};
export default Header;
