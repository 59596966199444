import Button from '@/components/shared/Button';
import { ButtonType } from '@/types';
import React from 'react';
import { dashboardSubnav } from '@/pages/dashboard';
import { fundsSubnav } from '@/pages/funds';
import { protectionSubnav } from '@/pages/protected-invest';
import { useRouter } from 'next/router';
import { useTheme } from '@chakra-ui/react';

interface Props {
  href: string;
  text: string;
}

const subnavItems = (subnav: { href: string; label: string }[]) => {
  return subnav.map((items) => items.href);
};

const SUBNAV_MAPPING = {
  '/dashboard': subnavItems(dashboardSubnav),
  '/funds': subnavItems(fundsSubnav),
  '/protected-invest': subnavItems(protectionSubnav),
  '/developers': ['/developers'],
  '/': [],
};

const isActive = (pathname: string, href: string): boolean => {
  const matchingItems = SUBNAV_MAPPING[href];

  return matchingItems.includes(pathname);
};

const HeaderLink: React.FC<Props> = ({ href, text }: Props) => {
  const router = useRouter();
  const theme = useTheme();

  return (
    <Button
      onClick={() => router.push(href)}
      appButtonType={ButtonType.Ghost}
      color={isActive(router.pathname, href) && theme.utilityColors.accentColor}
    >
      {text}
    </Button>
  );
};
export default HeaderLink;
