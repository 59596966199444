const WHITELISTED_PATHNAMES = ['/', '/developers', '/terms', '/privacy'];

const BLOCKED_HOSTS = ['cozy-ui.vercel.app', 'cozy.finance', 'www.cozy.finance'];

const BLOCKED_COUNTRIES = [
  'AL', // Albania
  'BA', // Bosnia
  'BY', // Belarus
  'BA', // Bosnia
  'BG', // Bulgaria
  'BI', // Burundi
  'CF', // Central African Republic
  'CI', // Côte d' Ivoire
  'CD', // Congo, Democratic Republic of the
  'CU', // Cuba
  'HR', // Croatia
  'IQ', // Iraq
  'IR', // Iran
  'LB', // Lebanon
  'LY', // Libya
  'KP', // North Korea
  'ME', // Montenegro
  'MK', // North Macedonia
  'MM', // Myanmar
  'RO', // Romania
  'RU', // Russia
  'RS', // Serbia
  'SI', // Slovenia
  'SD', // Sudan
  'SO', // Somalia
  'SS', // South Sudan
  'SY', // Syrian Arab Republic
  'UA', // Ukraine
  'US', // Untied State of America
  'VE', // Venezuela
  'YE', // Yemen
  'ZW', // Zimbabwe
];

export const determineIfRegionBlocked = async (countryCode: string, host: string): Promise<boolean> => {
  const countryIsBlocked = determineIfCountryIsBlocked(countryCode);
  const hostIsBlocked = determineIfHostIsBlocked(host);

  return countryIsBlocked && hostIsBlocked;
};

export const determineIfCountryIsBlocked = (countryCode: string): boolean => {
  return BLOCKED_COUNTRIES.includes(countryCode);
};

export const determineIfPathnameIsWhitelisted = (pathname: string): boolean => {
  return WHITELISTED_PATHNAMES.includes(pathname);
};

export const determineIfHostIsBlocked = (host: string): boolean => {
  return BLOCKED_HOSTS.includes(host);
};
